import React from 'react';
import { withSvgIcon } from './withSvgIcon';

export const FEthIcon = withSvgIcon(
  <>
    <path
      d="M87.0096 3.48411C95.0481 -1.16137 104.952 -1.16137 112.99 3.48411L177.01 40.4812C185.048 45.1267 190 53.7119 190 63.0029V136.997C190 146.288 185.048 154.873 177.01 159.519L112.99 196.516C104.952 201.161 95.0481 201.161 87.0096 196.516L22.9904 159.519C14.9519 154.873 10 146.288 10 136.997V63.0029C10 53.7119 14.9519 45.1267 22.9904 40.4812L87.0096 3.48411Z"
      fill="#006DFF"
    />
    <path
      opacity="0.6"
      d="M99.9801 121.88L132.85 102.011L99.9801 86.7559L67.1499 102.011L99.9801 121.88Z"
      fill="white"
    />
    <path
      opacity="0.45"
      d="M99.9801 86.756V46.25L67.1499 102.012L99.9801 121.88V86.756Z"
      fill="white"
    />
    <path
      opacity="0.8"
      d="M99.98 121.88L132.85 102.012L99.98 46.25V86.756V121.88Z"
      fill="white"
    />
    <path
      opacity="0.45"
      d="M99.9801 128.233L67.1499 108.405L99.9801 155.75V128.233Z"
      fill="white"
    />
    <path
      opacity="0.8"
      d="M132.85 108.405L99.98 128.233V155.75L132.85 108.405Z"
      fill="white"
    />
  </>,
  { viewBox: '0 0 200 200' },
);
