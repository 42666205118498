import { useQuery } from '@redux-requests/react';
import React from 'react';
import { useIsMDUp } from '../../../../common/hooks/useTheme';
import { Blockchain } from '../../../../common/types';
import { WalletBalanceComponent } from '../../../layout/components/WalletBalance';
import { WalletBalanceItem } from '../../../layout/components/WalletBalanceItem';
import { getNetworkNameByToken } from '../../../layout/utils/token';
import { fetchStats, IFetchStats } from '../../actions/fetchStats';

interface IWalletBalanceProps {
  className?: string;
}

export const WalletBalance = ({ className }: IWalletBalanceProps) => {
  const isMDUp = useIsMDUp();
  const { data: fetchStatsData } = useQuery<IFetchStats | null>({
    type: fetchStats,
  });

  return (
    <WalletBalanceComponent
      network={getNetworkNameByToken(Blockchain.binance)}
      showInARow={!isMDUp}
    >
      <WalletBalanceItem name="BNB" value={fetchStatsData?.walletBalance} />
    </WalletBalanceComponent>
  );
};
