import React, { ReactNode } from 'react';
import { useLayoutStyles } from './useLayoutStyles';

export interface ILayoutProps {
  children?: ReactNode;
  headerSlot?: ReactNode;
  footerSlot?: ReactNode;
}

export const Layout = ({ children, headerSlot, footerSlot }: ILayoutProps) => {
  const classes = useLayoutStyles();

  return (
    <div className={classes.root}>
      {headerSlot}
      <main className={classes.content}>{children}</main>
      {footerSlot}
    </div>
  );
};
