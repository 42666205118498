import { useQuery } from '@redux-requests/react';
import React, { useCallback, useMemo } from 'react';
import { WalletBalanceComponent } from '.';
import { useIsMDUp } from '../../../../common/hooks/useTheme';
import { ResponseData } from '../../../../components/ResponseData';
import { UserActionTypes } from '../../../../store/actions/UserActions';
import { IStakerStats } from '../../../../store/apiMappers/stakerStatsApi';
import { IUserInfo } from '../../../../store/apiMappers/userApi';
import { AvalancheActions } from '../../../stake-avax/actions/AvalancheActions';
import { IClaimStats } from '../../../stake-avax/api/types';
import { fetchStats as fetchPolygonStats } from '../../../stake-polygon/actions/fetchStats';
import {
  addTokenToWallet,
  getNetworkNameByToken,
  getVisibleTokens,
  TokenType,
} from '../../utils/token';
import { WalletBalanceItem } from '../WalletBalanceItem';

export const WalletBalance = () => {
  const isMDUp = useIsMDUp();
  const { data: accountData } = useQuery<IUserInfo | null>({
    type: UserActionTypes.FETCH_ACCOUNT_DATA,
  });

  const { data: stakerStats } = useQuery<IStakerStats | null>({
    type: UserActionTypes.FETCH_STAKER_STATS,
  });

  const { data: avaxClaimStats } = useQuery<IClaimStats | null>({
    type: AvalancheActions.fetchClaimStats,
  });

  const { data: polygonStats } = useQuery<ResponseData<
    typeof fetchPolygonStats
  > | null>({
    type: fetchPolygonStats.toString(),
  });

  const aETHBalance = stakerStats?.aEthBalance;
  const aETHbBalance = stakerStats?.fEthBalance;
  const ethBalance = accountData?.ethereumBalance;
  const bnbBalance = accountData?.bnbBalance;
  const ankrBalance = accountData?.ankrBalance;
  const avaxBalance = stakerStats?.balance;
  const aAVAXbBalance = avaxClaimStats?.balance;
  const maticBalance = polygonStats?.maticBalance;
  const aMATICbBalance = polygonStats?.aMaticbBalance;
  const blockchainType = accountData?.blockchainType;

  const visibleTokens = useMemo(
    () =>
      getVisibleTokens(
        [
          { name: 'aETHc', balance: aETHBalance },
          { name: 'aETHb', balance: aETHbBalance },
          { name: 'ETH', balance: ethBalance },
          { name: 'BNB', balance: bnbBalance },
          { name: 'ANKR', balance: ankrBalance },
          { name: 'AVAX', balance: avaxBalance },
          { name: 'aAVAXb', balance: aAVAXbBalance },
          { name: 'MATIC', balance: maticBalance },
          { name: 'aMATICb', balance: aMATICbBalance },
        ],
        blockchainType,
      ),
    [
      aAVAXbBalance,
      aETHBalance,
      aETHbBalance,
      aMATICbBalance,
      ankrBalance,
      avaxBalance,
      blockchainType,
      bnbBalance,
      ethBalance,
      maticBalance,
    ],
  );

  const handleTokenClick = useCallback(
    (tokenName: TokenType) => async () => {
      const token = visibleTokens.find(t => t.name === tokenName);
      if (token) {
        await addTokenToWallet(token);
      }
    },
    [visibleTokens],
  );

  return (
    <WalletBalanceComponent
      network={getNetworkNameByToken(blockchainType)}
      showInARow={!isMDUp}
    >
      {visibleTokens.map(({ name, balance }) => (
        <WalletBalanceItem
          onClick={handleTokenClick(name)}
          key={name}
          name={name}
          value={balance}
        />
      ))}
    </WalletBalanceComponent>
  );
};
