import { EARN_LINK } from 'common/const';
import { sessionServiceInstance, SessionServiceKeys } from 'common/services';
import { t } from 'common/utils/intl';
import React, { ReactNode, useCallback, useState } from 'react';
import { Footer } from '../Footer';
import { Header } from '../Header';
import { Layout } from '../Layout';
import { NewEarnBanner } from '../NewEarnBanner';

interface IDefaultLayout {
  children?: ReactNode;
}

export const DefaultLayout = ({ children }: IDefaultLayout) => {
  const [canShowBanner, setShowBanner] = useState(
    !sessionServiceInstance.getItem(SessionServiceKeys.HIDE_OLD_UI_POPUP),
  );

  const handleCloseSwitchBanner = useCallback(() => {
    sessionServiceInstance.setItem(SessionServiceKeys.HIDE_OLD_UI_POPUP, true);
    setShowBanner(false);
  }, []);

  return (
    <Layout
      headerSlot={
        <>
          {canShowBanner && (
            <NewEarnBanner
              text={t('earn-banner.text')}
              btnText={t('earn-banner.button')}
              href={EARN_LINK}
              onClose={handleCloseSwitchBanner}
            />
          )}
          <Header />
        </>
      }
      footerSlot={<Footer />}
    >
      {children}
    </Layout>
  );
};
