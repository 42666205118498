import React, { ReactNode } from 'react';
import { Footer } from '../../../layout/components/Footer';
import { Layout } from '../../../layout/components/Layout';
import { BnbHeader } from '../BnbHeader';

interface IBnbLayoutProps {
  children?: ReactNode;
}

export const BnbLayout = ({ children }: IBnbLayoutProps) => {
  return (
    <Layout headerSlot={<BnbHeader />} footerSlot={<Footer />}>
      {children}
    </Layout>
  );
};
