import { useQuery } from '@redux-requests/react';
import { connect, IConnect } from '../actions/connect';

export const useConnectData = () => {
  const { data } = useQuery<IConnect | null>({
    type: connect,
  });

  return {
    isConnected: data?.isConnected ?? false,
    chainId: data?.chainId,
    accounts: data?.accounts,
    walletAddress: data?.walletAddress,
  };
};
