import { useDispatchRequest } from '@redux-requests/react';
import React from 'react';
import { ReactComponent as BinanceSmartChainIcon } from '../../../../common/assets/binanceSmartChainIcon.svg';
import { t } from '../../../../common/utils/intl';
import {
  NetworkSelectorItem,
  NetworkSelectorList,
} from '../../../../components/NetworkSelector';
import { switchNetworkToBbcMainnet } from '../../actions/switchNetwork';
import { useConnectData } from '../../hooks/useConnectData';

export const NetworkSelector = () => {
  const dispatchRequest = useDispatchRequest();
  const { isConnected } = useConnectData();

  const handleClick = () => {
    dispatchRequest(switchNetworkToBbcMainnet());
  };

  return (
    <NetworkSelectorList>
      <NetworkSelectorItem
        onClick={handleClick}
        icon={<BinanceSmartChainIcon />}
        title={t('connect.networks.binance')}
        disabled={!isConnected}
      />
    </NetworkSelectorList>
  );
};
