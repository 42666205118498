import React from 'react';
import { withSvgIcon } from '../../UiKit/Icons/withSvgIcon';

export const ZendeskHelpIcon = withSvgIcon(
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 1.5C8.43959 1.5 1.5 8.43959 1.5 17C1.5 25.5604 8.43959 32.5 17 32.5C25.5604 32.5 32.5 25.5604 32.5 17C32.5 8.43959 25.5604 1.5 17 1.5ZM0.5 17C0.5 7.8873 7.8873 0.5 17 0.5C26.1127 0.5 33.5 7.8873 33.5 17C33.5 26.1127 26.1127 33.5 17 33.5C7.8873 33.5 0.5 26.1127 0.5 17ZM17 7.5C11.7533 7.5 7.5 11.7533 7.5 17C7.5 22.2467 11.7533 26.5 17 26.5C22.2467 26.5 26.5 22.2467 26.5 17C26.5 11.7533 22.2467 7.5 17 7.5ZM6.5 17C6.5 11.201 11.201 6.5 17 6.5C22.799 6.5 27.5 11.201 27.5 17C27.5 22.799 22.799 27.5 17 27.5C11.201 27.5 6.5 22.799 6.5 17Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.4961 2.84705L25.8344 3.06188C27.5924 4.17843 29.1246 5.61831 30.3475 7.29791L30.5992 7.64349L25.513 12.7296L25.185 12.1744C24.199 10.5058 22.7171 9.16475 20.943 8.35426L20.2881 8.05506L25.4961 2.84705ZM25.6328 4.12458L21.9952 7.76214C23.4858 8.56977 24.7586 9.72738 25.7036 11.1248L29.2833 7.54512C28.2601 6.21784 27.0266 5.06101 25.6328 4.12458ZM8.05506 20.2881L8.35426 20.943C9.16475 22.7171 10.5058 24.199 12.1744 25.185L12.7296 25.513L7.64349 30.5992L7.29791 30.3475C5.61831 29.1246 4.17843 27.5924 3.06188 25.8344L2.84705 25.4961L8.05506 20.2881ZM4.12458 25.6328C5.06101 27.0266 6.21784 28.2601 7.54512 29.2833L11.1248 25.7036C9.72738 24.7586 8.56977 23.4858 7.76214 21.9952L4.12458 25.6328Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.2471 3.00446L13.4146 8.17198L12.7904 8.48106C11.0478 9.34387 9.60745 10.726 8.67204 12.4249L8.35106 13.0079L3.23389 7.89073L3.47451 7.54706C4.66646 5.84466 6.17159 4.37776 7.90646 3.22985L8.2471 3.00446ZM4.53835 7.78099L8.1323 11.3749C9.03758 9.95062 10.2765 8.75935 11.7394 7.91092L8.12149 4.29306C6.74731 5.25506 5.53628 6.43435 4.53835 7.78099ZM25.828 20.5853L30.9955 25.7528L30.7701 26.0935C29.6222 27.8283 28.1553 29.3335 26.4529 30.5254L26.1092 30.7661L20.992 25.6489L21.575 25.3279C23.2739 24.3925 24.6561 22.9521 25.5189 21.2095L25.828 20.5853ZM26.089 22.2606C25.2406 23.7234 24.0493 24.9624 22.625 25.8676L26.219 29.4616C27.5656 28.4637 28.7449 27.2526 29.7069 25.8784L26.089 22.2606Z"
      fill="white"
    />
  </>,

  { viewBox: '0 0 34 34' },
);
