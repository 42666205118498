import { RequestAction } from '@redux-requests/core';
import { BigNumber } from 'bignumber.js';
import { createAction } from 'redux-smart-actions';
import { BnbSdk } from '../api/BnbSdk';
import { getBnbAccountData } from '../api/getBnbAccountData';
import { getStakingBalance } from '../api/getStakingBalance';
import { getValidator } from '../api/getValidator';
import { ACTIONS_PREFIX, VALIDATOR_ADDRESS } from '../const';

export interface IFetchStats {
  walletBalance: BigNumber;
  delegated: BigNumber;
  rewardsAmount: BigNumber;
  commissionRate: BigNumber;
  apr: BigNumber;
}

export const fetchStats = createAction<RequestAction<any, IFetchStats>>(
  `${ACTIONS_PREFIX}fetchStats`,
  (accountId: string) => ({
    request: {
      promise: (async function (): Promise<IFetchStats> {
        const bnbSdk = new BnbSdk();
        const { address } = await bnbSdk.getBep2Address(accountId);
        const accountData = await getBnbAccountData(address);
        const balance = accountData.balances.find(
          item => item.symbol === 'BNB',
        );

        if (!balance) {
          throw new Error('Balance is not found');
        }

        const [{ delegated, rewardsAmount }, { commissionRate, apr }] =
          await Promise.all([
            await getStakingBalance(address),
            await getValidator(VALIDATOR_ADDRESS),
          ]);

        return {
          walletBalance: balance.free,
          delegated,
          rewardsAmount,
          commissionRate,
          apr,
        };
      })(),
    },
  }),
);
