import { Container, Paper } from '@material-ui/core';
import { isWeb3Injected, web3Enable } from '@polkadot/extension-dapp';
import React, { useEffect, useState } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { DefaultLayout } from '../../modules/layout/components/DefautLayout';
import { PolkadotExtension } from '../PolkadotExtension';
import { QueryLoadingAbsolute } from '../QueryLoading/QueryLoading';
import { useGuardRoutePolkadotStyles } from './useGuardRoutePolkadotStyles';

export const GuardRoutePolkadot = ({ ...routeProps }: RouteProps) => {
  const classes = useGuardRoutePolkadotStyles();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      await web3Enable('stakefi.com');
      setLoading(false);
    })();
  }, []);

  if (loading) {
    return <QueryLoadingAbsolute />;
  }

  if (!isWeb3Injected) {
    return (
      <DefaultLayout>
        <Container maxWidth="md">
          <Paper variant="outlined" square={false} className={classes.paper}>
            <PolkadotExtension />
          </Paper>
        </Container>
      </DefaultLayout>
    );
  }

  return <Route {...routeProps} />;
};
