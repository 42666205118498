import { ButtonBase, Menu, MenuItem } from '@material-ui/core';
import classNames from 'classnames';
import React, { MouseEvent, ReactNode, useState } from 'react';
import { uid } from 'react-uid';
import { useWalletBalance } from './useWalletBalanceStyles';

interface IWalletBalanceProps {
  className?: string;
  showInARow?: boolean;
  network?: string;
  children?: ReactNode;
}

export const WalletBalanceComponent = ({
  className,
  showInARow,
  network,
  children,
}: IWalletBalanceProps) => {
  const classes = useWalletBalance();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return showInARow ? (
    <div className={className}>
      <div className={classes.rowBalanceList}>
        {React.Children.map(children, (child, index) => (
          <div className={classes.rowBalanceItem} key={uid(index)}>
            {child}
          </div>
        ))}
      </div>
    </div>
  ) : (
    <div className={classNames(classes.walletBalance, className)}>
      <ButtonBase
        onClick={handleClick}
        aria-controls="menu"
        aria-haspopup="true"
      >
        {React.Children.map(children, (child, index) => {
          if (index === 0) {
            return React.cloneElement(child as any, {
              onClick: undefined,
            });
          }
        })}
      </ButtonBase>

      <Menu
        id="menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        classes={{ list: classes.listMenu, paper: classes.paperMenu }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {React.Children.map(children, (child, index) => (
          <MenuItem className={classes.menuItem} key={uid(index)} value={index}>
            {child}
          </MenuItem>
        ))}
      </Menu>

      {network && <div className={classes.network}>{network}</div>}
    </div>
  );
};
