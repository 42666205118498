import BigNumber from 'bignumber.js';
import { FC } from 'react';
import { Blockchain } from '../../../common/types';
import { FEthIcon } from '../../../UiKit/Icons/FEthIcon';
import { StkrSdk } from '../../api';
import { configFromEnv } from '../../api/config';
import IERC20 from '../../api/contract/IERC20.json';
import { ReactComponent as AavaxbIcon } from '../assets/aavaxb.svg';
import { ReactComponent as AETHIcon } from '../assets/aeth.svg';
import { ReactComponent as AmaticbIcon } from '../assets/amaticb.svg';
import { ReactComponent as AnkrIcon } from '../assets/ankr.svg';
import { ReactComponent as AvaxIcon } from '../assets/avax.svg';
import { ReactComponent as BNBIcon } from '../assets/binance.svg';
import { ReactComponent as ETHIcon } from '../assets/ethereum.svg';
import { ReactComponent as MaticIcon } from '../assets/matic.svg';

const tokens = [
  'aETHc',
  'aETHb',
  'ETH',
  'BNB',
  'ANKR',
  'AVAX',
  'aAVAXb',
  'MATIC',
  'aMATICb',
] as const;

export type TokenType = typeof tokens[number];

const tokenIcons: Record<TokenType, FC> = {
  aETHc: AETHIcon,
  aETHb: FEthIcon,
  ANKR: AnkrIcon,
  ETH: ETHIcon,
  BNB: BNBIcon,
  AVAX: AvaxIcon,
  aAVAXb: AavaxbIcon,
  MATIC: MaticIcon,
  aMATICb: AmaticbIcon,
};

export const getTokenIcon = (token: TokenType) => tokenIcons[token];

const getContractByToken = (token: TokenType, blockchainType: Blockchain) => {
  const stkrConfig = configFromEnv();

  switch (blockchainType) {
    case Blockchain.ethereum: {
      switch (token) {
        case 'aETHc':
          return stkrConfig.contractConfig.aethContract ?? '';
        case 'aETHb':
          return stkrConfig.contractConfig.fethContract ?? '';
        case 'ANKR':
          return stkrConfig.contractConfig.ankrContract ?? '';
        case 'AVAX':
          return stkrConfig.contractConfig.futureBondAVAX ?? '';
        case 'aAVAXb':
          return stkrConfig.contractConfig.futureBondAVAX;
        case 'MATIC':
          return stkrConfig.contractConfig.maticToken;
        case 'aMATICb':
          return stkrConfig.contractConfig.aMaticbToken;
        default:
          return '';
      }
    }
    case Blockchain.binance: {
      switch (token) {
        case 'ETH':
          return stkrConfig.binanceConfig.pegEthContract;
        case 'aETHc':
          return stkrConfig.binanceConfig.aethContract;
        case 'AVAX':
          return stkrConfig.binanceConfig.futureBondAVAX;
        default:
          return '';
      }
    }
    case Blockchain.avalanche: {
      switch (token) {
        case 'aAVAXb':
          return stkrConfig.avalancheConfig.futureBondAVAX;
        default:
          return '';
      }
    }
    default:
      return '';
  }
};

export const addTokenToWallet = async (token: IToken) => {
  if (!token.address) {
    return;
  }
  const stkrSdk = StkrSdk.getForEnv();
  const tokenContract = stkrSdk
    .getKeyProvider()
    .createContract(IERC20 as any, token.address);
  const decimals = await tokenContract.methods.decimals().call();

  await stkrSdk.getKeyProvider().addTokenToWallet({
    address: token.address,
    symbol: token.name,
    decimals,
  });
};

export const getMainTokenName = (blockchainType?: Blockchain): TokenType => {
  switch (blockchainType) {
    case Blockchain.avalanche:
      return 'AVAX';
    case Blockchain.binance:
      return 'BNB';
    case Blockchain.ethereum:
    default:
      return 'ETH';
  }
};

interface IToken {
  name: TokenType;
  balance?: BigNumber;
  address?: string;
}
export const getVisibleTokens = (
  tokens: IToken[],
  blockchainType?: Blockchain,
): IToken[] => {
  if (!blockchainType) {
    return [];
  }

  tokens.forEach(
    token => (token.address = getContractByToken(token.name, blockchainType)),
  );
  const mainTokenName = getMainTokenName(blockchainType);
  const mainToken = tokens.find(token => token.name === mainTokenName);
  if (!mainToken) {
    return [];
  }

  return [
    mainToken,
    ...tokens.filter(
      token => token.name !== mainTokenName && token.balance?.isGreaterThan(0),
    ),
  ];
};

export const getNetworkNameByToken = (blockchainType?: Blockchain) => {
  switch (blockchainType) {
    case Blockchain.avalanche:
      return 'Avalanche';
    case Blockchain.binance:
      return 'Binance';
    case Blockchain.ethereum:
      return 'Ethereum';
    default:
      return '';
  }
};
