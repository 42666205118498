import React from 'react';
import { ASSETS_PATH } from '../../const';
import SectionWrapper from '../SectionWrapper/SectionWrapper';
import { useStyles } from './Styles';

const BgVideo = () => {
  const classes = useStyles();

  return (
    <SectionWrapper needTimeout={false}>
      <div className={classes.bgVideo}>
        <video width="100%" height="100%" autoPlay loop muted playsInline>
          <source src={`${ASSETS_PATH}/bgVideo.mp4`} type="video/mp4" />
        </video>
        <div className={classes.gradient} />
      </div>
    </SectionWrapper>
  );
};

export default BgVideo;
