import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useNewEarnBannerStyles = makeStyles<Theme>(theme => ({
  root: {
    width: '100%',
    background: theme.palette.text.primary,
    padding: theme.spacing(0, 0, 1),

    [theme.breakpoints.up('sm')]: {
      padding: 0,
    },
  },

  container: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr 12px',
    gap: theme.spacing(0, 2),
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',

    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(3, auto)',
      gap: theme.spacing(0, 3),
    },
  },

  text: {
    fontSize: 14,
    fontWeight: 600,
    fontFamily: 'sans-serif',
    color: theme.palette.background.default,

    [theme.breakpoints.up('sm')]: {
      fontSize: 16,
    },
  },

  image: {
    width: '1em',
    height: '1em',
    fontSize: 40,

    [theme.breakpoints.up('sm')]: {
      fontSize: 60,
    },
  },

  button: {
    gridColumn: '1 / 3',
    fontSize: 16,
    height: 36,
    fontFamily: 'sans-serif',
    padding: theme.spacing(0, 5.5),

    [theme.breakpoints.up('sm')]: {
      gridColumn: 'auto',
    },
  },

  closeWrapper: {
    border: 'none',
    background: 'none',
    color: theme.palette.grey[500],
    cursor: 'pointer',

    position: 'absolute',
    right: theme.spacing(2),

    '& > svg': {
      marginBottom: -1,
    },
  },
}));
