import React from 'react';
import { useConnect } from '../../common/hooks/useConnect';
import { INetwork } from '../GuardRoute/useNetworks';
import { NetworkSelector } from '../NetworkSelector/NetworkSelector';
import { ConnectComponent } from './ConnectComponent';

export const Connect = ({ networks }: { networks: INetwork[] }) => {
  const { dispatchConnect } = useConnect();

  return (
    <ConnectComponent
      onConnectClick={dispatchConnect}
      networksSlot={<NetworkSelector networks={networks} />}
    />
  );
};
