import { useMediaQuery } from '@material-ui/core';
import React, { useMemo } from 'react';
import { ASSETS_PATH } from '../../const';
import Modal from '../Modal/Modal';
import { useStyles } from './Styles';

interface ILink {
  text: string;
  link: string;
}

const menuLinks: ILink[] = [
  {
    text: 'Main',
    link: '/',
  },
  {
    text: 'Community',
    link: 'https://t.me/ankrnetwork',
  },
  {
    text: 'Docs',
    link: 'https://docs.ankr.com/',
  },
  {
    text: 'Litepaper',
    link: `${ASSETS_PATH}/litepaper.pdf`,
  },
];

interface MenuProps {
  onMenuToggle: () => void;
  isVisible: boolean;
}
const Menu = ({ onMenuToggle, isVisible }: MenuProps) => {
  const classes = useStyles();
  const isDesktop = useMediaQuery('(min-width:960px)');

  const socLinks: ILink[] = useMemo(
    () => [
      {
        text: isDesktop ? 'Twitter' : 'Tw',
        link: 'https://twitter.com/ankr?s=20',
      },
      {
        text: isDesktop ? 'Telegram chat' : 'Tg chat',
        link: 'https://t.me/ankrnetwork',
      },
      {
        text: isDesktop ? 'Telegram announcements' : 'Tg ann',
        link: 'https://t.me/anrknetworkann',
      },
      {
        text: isDesktop ? 'Discord' : 'Di',
        link: 'https://discord.gg/R9mzypfZb8',
      },
      {
        text: isDesktop ? 'Reddit' : 'Re',
        link: 'https://www.reddit.com/r/Ankrofficial/',
      },
      {
        text: isDesktop ? 'Medium' : 'Me',
        link: 'https://medium.com/ankr-network',
      },
    ],
    [isDesktop],
  );

  return (
    <Modal isVisible={isVisible} onToggle={onMenuToggle} hideAll={false}>
      <div className={classes.content}>
        {menuLinks.map(({ link, text }) => (
          <div key={text} className={classes.menuItem}>
            <a href={link} target="_blank" rel="noreferrer">
              {text}
            </a>
          </div>
        ))}
      </div>

      <div className={classes.social}>
        {socLinks.map(({ text, link }) => (
          <a
            className={classes.socialLink}
            href={link}
            key={link}
            target="_blank"
            rel="noreferrer"
          >
            {text}
          </a>
        ))}
      </div>
    </Modal>
  );
};

export default Menu;
