import classNames from 'classnames';
import React from 'react';
import { uid } from 'react-uid';
import { SOCIAL_LINK } from '../../../../common/const';
import { useLocaleMemo } from '../../../../common/hooks/useLocaleMemo';
import { t, tHTML } from '../../../../common/utils/intl';
import { Curtains } from '../../../../UiKit/Curtains';
import { NavLink } from '../../../../UiKit/NavLink';
import { Social } from '../Social';
import { useHeaderStyles } from './FooterStyles';

export interface IFooterProps {
  className?: string;
}

export const FooterComponent = ({ className }: IFooterProps) => {
  const classes = useHeaderStyles();

  const year = new Date().getFullYear();

  const links = useLocaleMemo(
    () => [
      {
        label: t('navigation.whitepaper-en'),
        href: SOCIAL_LINK.whitepaperEn,
      },
      {
        label: t('navigation.whitepaper-ch'),
        href: SOCIAL_LINK.whitepaperCh,
      },
    ],
    [],
  );

  return (
    <footer className={classNames(classes.component, className)}>
      <Curtains classes={{ root: classes.wrapper }}>
        <p className={classes.copyright}>
          {tHTML('navigation.copyright', { year: year })}
        </p>

        <ul className={classes.list}>
          {links.map(link => (
            <li className={classes.item} key={uid(link)}>
              <NavLink
                className={classes.link}
                activeClassName={classes.active}
                href={link.href}
                size="small"
                color="secondary"
              >
                {link.label}
              </NavLink>
            </li>
          ))}
        </ul>

        <Social className={classes.social} />
      </Curtains>
    </footer>
  );
};

export const Footer = () => <FooterComponent />;
