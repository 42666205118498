import { EARN_CROWDLOANS_LINK } from 'common/const';
import { sessionServiceInstance, SessionServiceKeys } from 'common/services';
import { t } from 'common/utils/intl';
import { NewEarnBanner } from 'modules/layout/components/NewEarnBanner/NewEarnBanner';
import React, { useCallback, useState } from 'react';
import { Footer } from '../Footer';
import { Header } from '../Header';
import { useDefaultLayoutStyles } from './DefaultLayoutStyles';

export interface ILayoutProps {
  children?: React.ReactNode;
}

export const DefaultLayout = ({ children }: ILayoutProps) => {
  const [canShowBanner, setShowBanner] = useState(
    !sessionServiceInstance.getItem(SessionServiceKeys.HIDE_OLD_UI_POPUP),
  );
  const classes = useDefaultLayoutStyles();

  const handleCloseSwitchBanner = useCallback(() => {
    sessionServiceInstance.setItem(SessionServiceKeys.HIDE_OLD_UI_POPUP, true);
    setShowBanner(false);
  }, []);

  return (
    <>
      <div className={classes.root}>
        {canShowBanner && (
          <NewEarnBanner
            text={t('earn-banner.crowdloans-text')}
            btnText={t('earn-banner.crowdloans-button')}
            href={EARN_CROWDLOANS_LINK}
            onClose={handleCloseSwitchBanner}
          />
        )}
        <Header />
        <main className={classes.content}>{children}</main>
        <Footer />
      </div>
    </>
  );
};
