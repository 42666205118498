import { generatePath } from 'react-router';
import { getEnv } from '../modules/api/config';
import { ParachainNetwork } from '../modules/stake-polkadot/types/ParachainNetwork';
import { Env, ETH, Milliseconds } from './types';

export const EMPTY_PATH = '/';
export const INDEX_PATH = '/liquid-staking';
export const FEATURES_PATH = `${INDEX_PATH}/launchpad`;
export const ABOUT_AETH_PATH = `${INDEX_PATH}/aETHc`;
export const STAKER_PATH = `${INDEX_PATH}/ETH`;
export const STAKER_AVALANCHE_PATH = `${INDEX_PATH}/AVAX`;
export const STAKER_POLKADOT_PATH = `${INDEX_PATH}/:network`;

export const STAKER_STAKE_PATH = `${STAKER_PATH}/stake`;
export const STAKER_STAKE_ROUTE = `${STAKER_PATH}/stake/:token`;

export const PROVIDER_PATH = `${STAKER_PATH}/provider`;
export const PROVIDER_CREATE_NODE_PATH = `${PROVIDER_PATH}/node/create-node`;
export const PROVIDER_DEPOSIT_LIST_PATH = `${PROVIDER_PATH}/deposit/list`;
export const PROVIDER_DEPOSIT_PATH = `${PROVIDER_PATH}/deposit/create`;
export const PROVIDER_DEPOSIT_ROUTE = `${PROVIDER_DEPOSIT_PATH}/:type?`;

export const GOVERNANCE_PATH = '/governance';
export const GOVERNANCE_PROJECT_LIST_PATH = `${GOVERNANCE_PATH}/project/list`;
export const GOVERNANCE_CREATE_PROJECT_PATH = `${GOVERNANCE_PATH}/project/create`;
export const GOVERNANCE_PROJECT_PATH = `${GOVERNANCE_PATH}/project/view`;
export const GOVERNANCE_PROJECT_ROUTE = `${GOVERNANCE_PROJECT_PATH}/:projectId`;

export const ABOUT_SMARTCHAIN_PATH = '/bsc';
export const ANKR_IFRAME_PATH = '/3dparty';
export const ANKR_IFRAME_SIGNATURE_PATH = '/3dparty/signature';
export const BRIDGE_PATH = '/bridge';
export const BRIDGE_RECOVERY_PATH = `${BRIDGE_PATH}/recovery`;
export const CONVERT_ROUTE = '/convert/:from/:to';

export const PARACHAIN_BONDING_PATH = '/parachain-liquid-bonding';

export const ONE_INCH_API_BASE_URL = 'https://api.1inch.exchange/v3.0/';

export function getPolkadotPath(network: ParachainNetwork) {
  if (!Object.values(ParachainNetwork).includes(network)) {
    return FEATURES_PATH;
  }

  return generatePath(STAKER_POLKADOT_PATH, { network });
}

export function getGovernanceProjectPath(projectId: string) {
  return generatePath(GOVERNANCE_PROJECT_ROUTE, { projectId });
}

export const SOCIAL_LINK = {
  discord: 'https://discord.gg/uYaNu23Ww7',
  facebook: 'facebook',
  medium: 'https://medium.com/ankr-network',
  telegram: 'https://t.me/ankrnetwork',
  telegramAnnouncements: 'https://t.me/anrknetworkann',
  twitter: 'https://twitter.com/ankr',
  whitepaperCh: 'https://assets.ankr.com/files/stakefi_litepaper_cn.pdf',
  whitepaperEn: 'https://assets.ankr.com/files/stakefi_litepaper.pdf',
};

export const ANKR_DEPOSIT_LINK =
  'https://app.uniswap.org/#/swap?outputCurrency=0x8290333cef9e6d528dd5618fb97a76f268f3edd4';
export const DOCS_LINK = 'https://docs.ankr.com/';
export const LITEPAPER_LINK =
  'https://assets.ankr.com/files/stakefi_litepaper.pdf';
export const POLKADOT_EXTENSION_LINK = 'https://polkadot.js.org/extension/';
export const BINANCE_EXTENSION_LINK =
  'https://docs.binance.org/smart-chain/wallet/binance.html';
export const AETHB_1INCH_BUY_LINK =
  'https://app.1inch.io/#/1/classic/swap/ETH/0xd01ef7c0a5d8c432fc2d1a85c66cf2327362e5c6';
export const AETHC_1INCH_BUY_LINK =
  'https://app.1inch.io/#/1/classic/swap/ETH/aETHc';
export const ANKR_1INCH_BUY_LINK =
  'https://app.1inch.io/#/1/classic/swap/ETH/ANKR';

export const ZERO_ADDR = '0x0000000000000000000000000000000000000000';

export const EARN_LINK = 'https://www.ankr.com/earn/';
export const EARN_CROWDLOANS_LINK = `${EARN_LINK}liquid-crowdloan/`;

export const TOKENS_MAP = {
  // Main Map
  aETHb: 'fETH',
  aETHc: 'aETH',

  // Reverse Map
  aETH: 'aETHc',
  fETH: 'aETHb',
};

export const DEFAULT_ROUNDING = 2;
export const DEFAULT_FIXED = 4;
export const DEFAULT_STAKING_AMOUNT = 32;
export const ETH_DIVIDER = 10 ** 18;
export const ETH_SCALE_FACTOR = 10 ** 18;
export const MAX_PROVIDER_STAKING_AMOUNT = 16;
export const NOTIFICATION_AUTO_HIDE_DURATION: Milliseconds = 3000;
export const PROVIDER_MIN_BALANCE: ETH = 2;
export const PROVIDE_MIN_BALANCE: ETH = 2;
export const STAKER_RATE = 0.85;

export const ENABLE_AVA = true;
export const ENABLE_PROVIDER = true;

const env = getEnv();
export const isMainnet = env === Env.Production;

export const featuresConfig = {
  aEthVideo: false,
  bridge: false,
  avalancheBridge: false,
  avalancheUnstake: true,
  stakeKSM: isMainnet,
  stakeDOT: isMainnet,
  stakeWND: env === Env.Develop,
  stakeMATIC: true,
  polkadotStakeHistory: true,
  polkadotClaimHistory: false,
  tradingCockpit: true,
};
