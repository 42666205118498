import { resetRequests } from '@redux-requests/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { FEATURES_PATH } from '../../../../common/const';
import { Provider } from '../../../../common/types';
import { t } from '../../../../common/utils/intl';
import { binance as binanceIcon } from '../../../layout/components/assets/assets';
import { HeaderComponent } from '../../../layout/components/Header';
import { connect } from '../../actions/connect';
import { fetchStakingHistory } from '../../actions/fetchStakingHistory';
import { fetchStats } from '../../actions/fetchStats';
import { useConnectData } from '../../hooks/useConnectData';
import { WalletBalance } from '../WalletBalance';

export const BnbHeader = () => {
  const { isConnected, walletAddress } = useConnectData();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleDisconnect = () => {
    history.push(FEATURES_PATH);
    dispatch(
      resetRequests([
        connect.toString(),
        fetchStats.toString(),
        fetchStakingHistory.toString(),
      ]),
    );
  };

  return (
    <HeaderComponent
      isAuth={isConnected}
      walletAddress={walletAddress}
      walletType={Provider.binance}
      balanceSlot={<WalletBalance />}
      walletName={t('providers.binance')}
      walletIcon={binanceIcon}
      onDisconnectClick={handleDisconnect}
      showWalletExplorerLink={false}
    />
  );
};
