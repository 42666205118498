import Axios, { AxiosResponse } from 'axios';
import BigNumber from 'bignumber.js';

interface IAPIAccountData {
  account_number: number;
  address: string;
  balances: [
    {
      free: string;
      frozen: string;
      locked: string;
      symbol: 'BNB';
    },
  ];
  flags: number;
  public_key: number[];
  sequence: number;
}

interface IAccountData {
  account_number: number;
  address: string;
  balances: {
    free: BigNumber;
    frozen: BigNumber;
    locked: BigNumber;
    symbol: 'BNB';
  }[];
  flags: number;
  public_key: number[];
  sequence: number;
}

export async function getBnbAccountData(address: string) {
  const { data } = await Axios.get<any, AxiosResponse<IAPIAccountData>>(
    `https://dex.binance.org/api/v1/account/${address}`,
  );

  return mapAccountData(data);
}

function mapAccountData(data: IAPIAccountData): IAccountData {
  return {
    ...data,
    balances: data.balances.map(item => ({
      free: new BigNumber(item.free),
      frozen: new BigNumber(item.frozen),
      locked: new BigNumber(item.locked),
      symbol: item.symbol,
    })),
  };
}
