import { RequestAction } from '@redux-requests/core';
import { createAction as createSmartAction } from 'redux-smart-actions';
import { BnbSdk, IBnbAccount } from '../api/BnbSdk';
import { ACTIONS_PREFIX } from '../const';

export interface IConnect {
  accounts?: IBnbAccount[];
  isConnected: boolean;
  chainId?: string;
  walletAddress?: string;
}

export const connect = createSmartAction<RequestAction<any, IConnect>>(
  `${ACTIONS_PREFIX}connect`,
  () => ({
    request: {
      promise: (async function (): Promise<IConnect> {
        const bnbSdk = new BnbSdk();
        const ethAccounts = await bnbSdk.connect();
        const isConnected = await bnbSdk.getIsConnected();
        const accounts = isConnected ? await bnbSdk.getAccounts() : undefined;
        const walletAddress = ethAccounts ? ethAccounts[0] : undefined;

        return {
          isConnected,
          accounts,
          chainId: bnbSdk.chainId,
          walletAddress,
        };
      })(),
    },
    meta: {
      asMutation: false,
      showNotificationOnError: true,
    },
  }),
);
