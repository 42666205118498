import { useQuery } from '@redux-requests/react';
import { UserActionTypes } from '../../store/actions/UserActions';
import { IUserInfo } from '../../store/apiMappers/userApi';

export const useAccountData = () => {
  const { data, loading } = useQuery<IUserInfo | null>({
    type: UserActionTypes.FETCH_ACCOUNT_DATA,
  });

  return {
    address: data?.address,
    blockchainType: data?.blockchainType,
    walletType: data?.walletType,
    walletName: data?.walletName,
    isLoading: loading,
    networkName: data?.networkName,
  };
};
