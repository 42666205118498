import React from 'react';

export const Twitter = (props: React.SVGAttributes<any>) => {
  return (
    <svg width={28} height={28} viewBox="0 0 28 28" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.62 7.08a8.9 8.9 0 01-2.547.697 4.436 4.436 0 001.948-2.454A8.888 8.888 0 0121.205 6.4a4.437 4.437 0 00-7.557 4.045A12.584 12.584 0 014.506 5.81c-.38.654-.6 1.416-.6 2.228 0 1.539.784 2.897 1.974 3.69a4.426 4.426 0 01-2.01-.554v.055a4.437 4.437 0 003.559 4.348 4.447 4.447 0 01-2.003.074 4.441 4.441 0 004.141 3.08 8.896 8.896 0 01-5.509 1.9c-.358 0-.71-.023-1.058-.061a12.552 12.552 0 006.8 1.993c8.157 0 12.618-6.757 12.618-12.618 0-.194-.003-.384-.013-.574a8.97 8.97 0 002.212-2.297l.004.006z"
        fill="currentColor"
      />
    </svg>
  );
};

export const Telegram = (props: React.SVGAttributes<any>) => {
  return (
    <svg width={28} height={28} viewBox="0 0 28 28" fill="none" {...props}>
      <path
        d="M4.92 12.837l17.517-6.754c.813-.294 1.523.198 1.26 1.427V7.51L20.716 21.56c-.22.997-.813 1.239-1.64.77l-4.543-3.348-2.19 2.11c-.243.243-.447.447-.917.447l.323-4.622 8.418-7.605c.366-.322-.082-.504-.565-.183l-10.403 6.55-4.484-1.4c-.974-.308-.995-.973.206-1.442z"
        fill="currentColor"
      />
    </svg>
  );
};

export const Medium = (props: React.SVGAttributes<any>) => {
  return (
    <svg width={28} height={28} viewBox="0 0 28 28" fill="none" {...props}>
      <path
        d="M15.51 14.487c0 2.695-2.164 4.88-4.834 4.88-2.67 0-4.834-2.185-4.834-4.88 0-2.694 2.164-4.879 4.834-4.879 2.67 0 4.834 2.185 4.834 4.88zM20.813 14.487c0 2.537-1.082 4.594-2.417 4.594-1.335 0-2.418-2.057-2.418-4.594 0-2.536 1.083-4.593 2.417-4.593 1.335 0 2.418 2.056 2.418 4.593M22.982 14.487c0 2.272-.38 4.115-.85 4.115s-.85-1.842-.85-4.115c0-2.272.38-4.115.85-4.115s.85 1.843.85 4.115z"
        fill="currentColor"
      />
    </svg>
  );
};

export const Discord = (props: React.SVGAttributes<any>) => {
  return (
    <svg width={28} height={28} viewBox="0 0 28 28" fill="none" {...props}>
      <path
        d="M11.9313 12.1216C11.2799 12.1216 10.7656 12.7049 10.7656 13.4166C10.7656 14.1282 11.2913 14.7116 11.9313 14.7116C12.5828 14.7116 13.0971 14.1282 13.0971 13.4166C13.1085 12.7049 12.5828 12.1216 11.9313 12.1216ZM16.1028 12.1216C15.4513 12.1216 14.9371 12.7049 14.9371 13.4166C14.9371 14.1282 15.4628 14.7116 16.1028 14.7116C16.7542 14.7116 17.2685 14.1282 17.2685 13.4166C17.2685 12.7049 16.7542 12.1216 16.1028 12.1216Z"
        fill="currentColor"
      />
      <path
        d="M21.4308 2.3335H5.5696C4.23205 2.3335 3.14307 3.40683 3.14307 4.73683V20.5102C3.14307 21.8402 4.23205 22.9135 5.5696 22.9135H18.9925L18.3651 20.7552L19.8802 22.1435L21.3125 23.4502L23.8574 25.6668V4.73683C23.8574 3.40683 22.7684 2.3335 21.4308 2.3335ZM16.8618 17.5702C16.8618 17.5702 16.4357 17.0685 16.0806 16.6252C17.6312 16.1935 18.2231 15.2368 18.2231 15.2368C17.7378 15.5518 17.2761 15.7735 16.8618 15.9252C16.27 16.1702 15.7018 16.3335 15.1455 16.4268C14.0092 16.6368 12.9676 16.5785 12.0798 16.4152C11.4051 16.2868 10.8251 16.1002 10.3398 15.9135C10.0676 15.8085 9.77164 15.6802 9.47572 15.5168C9.44021 15.4935 9.4047 15.4818 9.36919 15.4585C9.34552 15.4468 9.33368 15.4352 9.32184 15.4235C9.10878 15.3068 8.99041 15.2252 8.99041 15.2252C8.99041 15.2252 9.55858 16.1585 11.0618 16.6018C10.7067 17.0452 10.2688 17.5702 10.2688 17.5702C7.65286 17.4885 6.65858 15.7968 6.65858 15.7968C6.65858 12.0402 8.36307 8.99516 8.36307 8.99516C10.0676 7.73516 11.6892 7.77016 11.6892 7.77016L11.8076 7.91016C9.67694 8.51683 8.6945 9.4385 8.6945 9.4385C8.6945 9.4385 8.9549 9.2985 9.39286 9.10016C10.6594 8.55183 11.6655 8.40016 12.0798 8.36516C12.1508 8.3535 12.21 8.34183 12.281 8.34183C13.0031 8.2485 13.8198 8.22516 14.672 8.3185C15.7965 8.44683 17.0039 8.7735 18.2349 9.4385C18.2349 9.4385 17.2998 8.5635 15.2876 7.95683L15.4533 7.77016C15.4533 7.77016 17.0749 7.73516 18.7794 8.99516C18.7794 8.99516 20.4839 12.0402 20.4839 15.7968C20.4839 15.7968 19.4778 17.4885 16.8618 17.5702Z"
        fill="currentColor"
      />
    </svg>
  );
};
