import Typography from '@material-ui/core/Typography';
import { t } from 'common/utils/intl';
import { Curtains } from 'UiKit/Curtains';
import { CloseIcon } from 'UiKit/Icons/CloseIcon';
import { NavLink } from 'UiKit/NavLink';
import rewardsIconPNG from './assets/rewards.png';
import { useNewEarnBannerStyles } from './useNewEarnBannerStyles';

interface INewEarnBannerProps {
  btnText: string;
  text: string;
  href?: string;
  onClose: () => void;
}

export const NewEarnBanner = ({
  btnText,
  text,
  href = '',
  onClose,
}: INewEarnBannerProps) => {
  const classes = useNewEarnBannerStyles();

  return (
    <div className={classes.root}>
      <Curtains className={classes.container}>
        <img
          src={rewardsIconPNG}
          className={classes.image}
          alt={t('earn-banner.image-alt')}
        />

        <Typography className={classes.text}>{text}</Typography>

        <NavLink
          variant="contained"
          color="primary"
          href={href}
          className={classes.button}
        >
          {btnText}
        </NavLink>

        <button
          className={classes.closeWrapper}
          type="button"
          onClick={onClose}
        >
          <CloseIcon size="xxs" htmlColor="currentColor" />
        </button>
      </Curtains>
    </div>
  );
};
