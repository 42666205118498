import { Container, Paper } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { tHTML } from '../../common/utils/intl';
import { Headline5 } from '../../UiKit/Typography';
import { useUnsupportedNetworkStyles } from './UnsupportedNetworkStyles';

export interface IUnsupportedNetwork {
  networksSlot?: ReactNode;
}

export const UnsupportedNetwork = ({ networksSlot }: IUnsupportedNetwork) => {
  const classes = useUnsupportedNetworkStyles();

  return (
    <Container maxWidth="md">
      <Paper variant="outlined" square={false} className={classes.paper}>
        <Headline5 className={classes.header}>
          {tHTML('connect.unsupported-network')}
        </Headline5>

        {networksSlot}
      </Paper>
    </Container>
  );
};
