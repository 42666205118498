import { RequestAction } from '@redux-requests/core';
import axios, { AxiosResponse } from 'axios';
import BigNumber from 'bignumber.js';
import { createAction } from 'redux-smart-actions';
import { BnbSdk } from '../api/BnbSdk';
import { ACTIONS_PREFIX } from '../const';

interface IApiGetStakingHistoryData {
  total: number;
  operations: {
    delegator: string;
    operationType: number;
    amount: number;
    srcValidator: null;
    validator: string;
    valName: string;
    time: string;
    txHash: string;
  }[];
}

export interface IStakingOperation {
  delegator: string;
  operationType: number;
  amount: BigNumber;
  srcValidator: null;
  validator: string;
  valName: string;
  time: Date;
  txHash: string;
}

export interface IGetStakingHistoryData {
  total: number;
  operations: IStakingOperation[];
}

export const fetchStakingHistory = createAction<
  RequestAction<IGetStakingHistoryData, IGetStakingHistoryData>
>(`${ACTIONS_PREFIX}fetchStakingHistory`, (accountId: string) => ({
  request: {
    promise: (async function (): Promise<IGetStakingHistoryData> {
      const bnbSdk = new BnbSdk();
      const { address } = await bnbSdk.getBep2Address(accountId);

      const { data } = await axios.get<
        any,
        AxiosResponse<IApiGetStakingHistoryData>
      >(
        `https://api.binance.org/v1/staking/chains/bsc/delegators/${address}/operations?limit=100&offset=0`,
      );

      return mapStakingHistory(data);
    })(),
  },
}));

function mapStakingHistory(
  data: IApiGetStakingHistoryData,
): IGetStakingHistoryData {
  return {
    ...data,
    operations: data.operations.map(item => {
      return {
        ...item,
        time: new Date(item.time),
        amount: new BigNumber(item.amount),
      };
    }),
  };
}
