import { createDriver as createAxiosDriver } from '@redux-requests/axios';
import { handleRequests } from '@redux-requests/core';
import { createDriver } from '@redux-requests/promise';
import axios from 'axios';
import { routerMiddleware, RouterRootState } from 'connected-react-router';
import { History } from 'history';
import { applyMiddleware, compose, createStore, Store } from 'redux';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import { getErrorMessage } from '../components/QueryError/QueryError';
import { configFromEnv } from '../modules/api/config';
import { NotificationActions } from './actions/NotificationActions';
import { rootSaga } from './effects';
import { createRootReducer } from './reducers';

export interface IApplicationStore extends RouterRootState {
  store: Store;
  saga: any;
}

export const persistApplicationStore = ({
  store,
}: Pick<IApplicationStore, 'store'>) => {
  return persistStore(store);
};

export const runApplicationStore = ({
  saga,
}: Pick<IApplicationStore, 'saga'>) => {
  saga.run(rootSaga);
};

export const createApplicationStore = ({
  history,
}: {
  history: History;
}): Omit<IApplicationStore, 'router'> => {
  const { requestsReducer, requestsMiddleware } = handleRequests({
    cache: true,
    driver: {
      default: createDriver({
        processResponse: response => ({ data: response }),
      }),
      axios: createAxiosDriver(
        axios.create({
          baseURL: configFromEnv().gatewayConfig.baseUrl,
        }),
      ),
    },
    onError: (error, action, store) => {
      if (action.meta?.showNotificationOnError) {
        store.dispatch(
          NotificationActions.showNotification({
            message: getErrorMessage(error),
            severity: 'error',
          }),
        );
      }

      throw error;
    },
  });

  const sagaMiddleware = createSagaMiddleware();

  const composeEnhancers =
    typeof window === 'object' &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          trace: true,
          traceLimit: 25,
        })
      : compose;

  const enhancer = composeEnhancers(
    applyMiddleware(
      ...requestsMiddleware,
      routerMiddleware(history),
      sagaMiddleware,
    ),
  );

  const store = createStore(
    createRootReducer(history, requestsReducer),
    enhancer,
  );

  return { store, saga: sagaMiddleware };
};
