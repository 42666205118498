import { useState } from 'react';
import { useInitEffect } from '../../../common/hooks/useInitEffect';

export const useIsBinanceReady = () => {
  const [isBinanceReady, setIsBinanceReady] = useState(!!window.BinanceChain);

  useInitEffect(() => {
    const windowLoadHandler = () => setIsBinanceReady(!!window.BinanceChain);
    window.addEventListener('load', windowLoadHandler);

    return function reset() {
      window.removeEventListener('load', windowLoadHandler);
    };
  });

  return isBinanceReady;
};
