import { Pathname } from 'history';
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

type TMeta = Array<JSX.IntrinsicElements['meta']>;

type THeadSEOMapData = {
  [key in EHeadSEOKeys]: IHeadSEOMapItem;
};

interface IHeadSEOMapItem {
  title: string;
  description: string;
  keywords: string | null;
}

interface IHeadSEOData {
  meta: TMeta;
  title: string;
}

enum EHeadSEOKeys {
  Default = 'Default',
}

const HEAD_SEO_MAP: THeadSEOMapData = {
  [EHeadSEOKeys.Default]: {
    title: 'Ankr Staking',
    description: 'Ankr | Staking',
    keywords: null,
  },
};

/**
 *  @TODO "_currPath" saved for the future SEO paths
 */
const getCurrSEOData = (_currPath: Pathname): IHeadSEOMapItem => {
  const currSEOMapKey: EHeadSEOKeys = EHeadSEOKeys.Default;

  return HEAD_SEO_MAP[currSEOMapKey];
};

export const HeadSEO = () => {
  const { pathname } = useLocation();

  const { meta, title } = useMemo<IHeadSEOData>((): IHeadSEOData => {
    const { title, description, keywords } = getCurrSEOData(pathname);

    const defaultMetaData: TMeta = [
      {
        name: 'description',
        content: description,
      },
    ];

    return {
      meta:
        keywords === null
          ? defaultMetaData
          : [
              ...defaultMetaData,
              {
                name: 'keywords',
                content: keywords,
              },
            ],
      title,
    };
  }, [pathname]);

  return <Helmet meta={meta} title={title} />;
};
