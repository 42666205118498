export {
  Headline1,
  Headline2,
  Headline3,
  Headline4,
  Headline5,
  SmallTitle,
  Body1,
  Body2,
} from './Typography';
