import BigNumber from 'bignumber.js';
import classNames from 'classnames';
import React from 'react';
import { DEFAULT_FIXED } from '../../../../common/const';
import { getTokenIcon, TokenType } from '../../utils/token';
import { useWalletBalanceItem } from './WalletBalanceItemStyles';

interface IWalletBalanceItemProps {
  name?: TokenType;
  value?: BigNumber;
  onClick?: () => void;
}

export const WalletBalanceItem = ({
  name,
  value,
  onClick,
}: IWalletBalanceItemProps) => {
  const classes = useWalletBalanceItem();
  if (!value) {
    return null;
  }

  const Icon = (name && getTokenIcon(name)) || 'span';

  return (
    <div
      onClick={onClick}
      title={name}
      className={classNames(classes.item, {
        [classes.itemClickable]: typeof onClick === 'function',
      })}
    >
      <Icon className={classes.icon} />
      {value.decimalPlaces(DEFAULT_FIXED).toFormat()}
    </div>
  );
};
