import { alpha, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const BALANCE_ITEM_GUTTER = 0.75;

export const useWalletBalance = makeStyles<Theme>(theme => ({
  rowBalanceList: {
    display: 'flex',
    flexFlow: 'row wrap',
    margin: theme.spacing(-BALANCE_ITEM_GUTTER * 2, -BALANCE_ITEM_GUTTER, 0),
  },

  rowBalanceItem: {
    maxWidth: '100%',
    flex: '0 0 auto',
    marginTop: theme.spacing(BALANCE_ITEM_GUTTER * 2),
    padding: theme.spacing(0, BALANCE_ITEM_GUTTER),
  },

  walletBalance: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
  },

  listMenu: {
    '& > li': {
      padding: theme.spacing(1.5, 0, 0, 0),
    },
  },

  menuItem: {
    cursor: 'default',
    display: 'block',
  },

  paperMenu: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(0.5, 2, 2, 2),
  },

  network: {
    lineHeight: '18px',
    padding: theme.spacing(0, 0, 0, 2),
    marginLeft: theme.spacing(2),
    borderLeft: `1px solid ${alpha(theme.palette.text.primary, 0.1)}`,
    color: theme.palette.text.secondary,
  },
}));
