import React, { useState } from 'react';
import classNames from 'classnames';

import Header from '../Header/Header';

import { useStyles } from './Styles';
import Title from '../Title/Title';
import WhySection from '../WhySection/WhySection';
import Products from '../Products/Products';
import Partners from '../Partners/Partners';
import Footer from '../Footer/Footer';
import FAQ from '../FAQ/FAQ';
import BgVideo from '../BgVideo/BgVideo';
import Menu from '../Menu/Menu';
import Button from '../Button/Button';

const App = () => {
  const classes = useStyles();
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpened(isOpened => !isOpened);
  };

  const handleClickLaunchApp = () => {
    window.open('https://www.ankr.com/earn/liquid-crowdloan/dot/', '_blank');
  };

  return (
    <div
      className={classNames(classes.container, {
        [classes.containerWithModal]: isMenuOpened,
      })}
    >
      <Header onMenuToggle={handleMenuToggle} isMenuOpened={isMenuOpened} />
      <Menu onMenuToggle={handleMenuToggle} isVisible={isMenuOpened} />

      <div className={classes.launchButton}>
        <Button text="Launch App" needFade onClick={handleClickLaunchApp} />
      </div>
      <BgVideo />
      <Title />
      {!isMenuOpened && (
        <>
          <WhySection />
          <Products />
          <Partners />
          <FAQ />
          <Footer />
        </>
      )}
    </div>
  );
};

export default App;
