import axios from 'axios';
import BigNumber from 'bignumber.js';

interface IApiValidatorData {
  validator: string;
  valName: string;
  commissionRate: number;
  votingPower: number;
  status: 0;
  votingPowerProportion: number;
  creationTime: number;
  apr: number;
  logoUrl: string;
  selfDelegator: string;
  selfStake: number;
  commissionMaxRate: number;
  commissionMaxChangeRate: number;
  website: string;
  detail: string;
  identity: string;
  sideConsAddr: string;
  sideFeeAddr: string;
  distributionAddr: string;
}

interface IValidatorData {
  commissionRate: BigNumber;
  apr: BigNumber;
}

export async function getValidator(address: string) {
  const { data } = await axios.get<IApiValidatorData>(
    `https://api.binance.org/v1/staking/chains/bsc/validators/${address}`,
  );

  return mapValidatorData(data);
}

function mapValidatorData(data: IApiValidatorData): IValidatorData {
  return {
    commissionRate: new BigNumber(data.commissionRate),
    apr: new BigNumber(data.apr),
  };
}
