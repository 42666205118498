import React from 'react';
import { useDispatch } from 'react-redux';
import { uid } from 'react-uid';
import { useAccountData } from '../../common/hooks/useAccountData';
import { useConnect } from '../../common/hooks/useConnect';
import { Provider } from '../../common/types';
import { switchNetwork } from '../../store/actions/switchNetwork';
import { INetwork } from '../GuardRoute/useNetworks';
import { NetworkSelectorItem } from './NetworkSelectorItem';
import { NetworkSelectorList } from './NetworkSelectorList';

const METAMASK_WALLET_NAME = 'MetaMask';

interface INetworkSelector {
  networks: INetwork[];
}

export const NetworkSelector = ({ networks }: INetworkSelector) => {
  const dispatch = useDispatch();
  const { isAuth } = useConnect();
  const { walletType, walletName } = useAccountData();

  const renderedItems = networks.map(network => {
    const onClick = () => {
      if (!isAuth) {
        return;
      }
      dispatch(switchNetwork(network.chainId));
    };

    return (
      <NetworkSelectorItem
        key={uid(network)}
        icon={network.icon}
        title={network.title}
        onClick={onClick}
        disabled={
          !isAuth ||
          walletType !== Provider.metamask ||
          walletName !== METAMASK_WALLET_NAME
        }
      />
    );
  });

  return <NetworkSelectorList>{renderedItems}</NetworkSelectorList>;
};
