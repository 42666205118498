import { fork } from 'redux-saga/effects';
import { userSaga } from './userSaga';
import { notificationSaga } from './notificationSaga';
import { governanceSaga } from '../../modules/governance/effects/governanceSaga';
import { polkadotSlotAuctionSaga } from '../../modules/polkadot-slot-auction/effects/polkadotSlotAuctionSaga';

function* rootSaga() {
  yield fork(userSaga);
  yield fork(notificationSaga);
  yield fork(governanceSaga);
  yield fork(polkadotSlotAuctionSaga);
}

export { rootSaga };
