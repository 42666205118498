import { singleton } from '../../../common/utils/Singleton';
import { BinanceNetworkId } from '../types';

const NOT_INSTALLED_ERROR_MESSAGE = 'Binance extension is not installed';

export interface IBnbAccount {
  id: string;
  name: string;
  icon: string;
  addresses: [
    {
      type: 'bbc-testnet';
      address: string;
    },
    {
      type: 'bbc-mainnet';
      address: string;
    },
    {
      type: 'eth';
      address: string;
    },
  ];
}

type TNetworkId = 'bbc-mainnet' | 'bsc-mainnet' | 'bbc-testnet' | 'bsc-testnet';

interface IBinanceAPI {
  autoRefreshOnNetworkChange: boolean;
  bbcSignTx: (e: any) => void;
  bnbSign: (n: any, i: any) => void;
  chainId: string;
  delegate: (n: any) => void;
  enable: () => void;
  isConnected: () => Promise<boolean>;
  on: (event: 'chainChanged' | string, callback: (args: any) => any) => void;
  redelegate: (n: any) => void;
  request: (e: any) => Promise<any>;
  requestAccounts: () => Promise<IBnbAccount[]>;
  requestAddresses: () => void;
  send: (e: any, n: any) => void;
  sendAsync: (e: any, n: any) => void;
  switchNetwork: (networkId: TNetworkId) => Promise<{ networkId: string }>;
  transfer: (n: any) => void;
  undelegate: (n: any) => void;
}

@singleton
export class BnbSdk {
  private rawAPI?: IBinanceAPI;
  private currentAccount?: string;
  public chainId?: string;

  constructor() {
    this.rawAPI = window.BinanceChain;
  }
  /**
   * Detect the Binance Smart Chain provider
   */
  public getIsReady() {
    return !!this.rawAPI;
  }

  public getRawAPI() {
    if (!this.rawAPI) {
      throw new Error(NOT_INSTALLED_ERROR_MESSAGE);
    }
    return this.rawAPI;
  }

  public getIsConnected() {
    if (!this.rawAPI) {
      throw new Error(NOT_INSTALLED_ERROR_MESSAGE);
    }
    return this.rawAPI.isConnected();
  }

  public async connect() {
    if (!this.rawAPI) {
      throw new Error(NOT_INSTALLED_ERROR_MESSAGE);
    }
    this.chainId = this.rawAPI.chainId;

    // set chainChanged event handler
    this.rawAPI.on('chainChanged', (chainId: string) => {
      // todo: need provide custom event listener
      this.handleChainChanged(chainId);
    });

    // https://docs.binance.org/smart-chain/wallet/wallet_api.html#using-the-provider
    try {
      const accounts: string[] = await this.rawAPI.request({
        method: 'eth_requestAccounts',
      });
      this.handleAccountsChanged(accounts);
      return accounts;
    } catch (err: any) {
      if (err.code === 4001) {
        // EIP-1193 userRejectedRequest error
        // If this happens, the user rejected the connection request.
        console.log('Please connect to MetaMask.');
        return null;
      } else {
        throw new Error(err);
      }
    }
  }

  public disconnect() {
    if (!this.rawAPI) {
      throw new Error(NOT_INSTALLED_ERROR_MESSAGE);
    }
    // todo: need to remove chainChanged event listener on disconnect
    this.rawAPI.on('chainChanged', () => null);
  }

  public switchNetwork(networkId: BinanceNetworkId) {
    if (!this.rawAPI) {
      throw new Error(NOT_INSTALLED_ERROR_MESSAGE);
    }

    return this.rawAPI.switchNetwork(networkId);
  }

  public getAccounts() {
    if (!this.rawAPI) {
      throw new Error(NOT_INSTALLED_ERROR_MESSAGE);
    }
    return this.rawAPI.requestAccounts();
  }

  public async getBep2Address(accountId: string) {
    const account = (await this.getAccounts()).find(
      item => item.id === accountId,
    );

    if (!account) {
      throw new Error("Account hasn't been found");
    }

    const address = account.addresses.find(item => {
      return item.type === 'bbc-mainnet';
    })?.address;

    if (!address) {
      throw new Error("Address hasn't been found");
    }

    return { address };
  }

  private handleChainChanged(_chainId: string) {
    window.location.reload();
  }

  private handleAccountsChanged(accounts: string[]) {
    if (accounts.length === 0) {
      // Binance Chain Wallet is locked or the user has not connected any accounts
      console.log('Please connect to Binance Chain Wallet.');
    } else if (accounts[0] !== this.currentAccount) {
      this.currentAccount = accounts[0];
      // Do any other work!
    }
  }
}
