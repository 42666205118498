import axios from 'axios';
import BigNumber from 'bignumber.js';

interface IApiStakingBalanceData {
  asset: 'BNB';
  delegated: number;
  unbonding: number;
}

interface IStakingBalanceData {
  asset: 'BNB';
  delegated: BigNumber;
  unbonding: BigNumber;
}

interface IApiRewardsDetails {
  chainId: 'bsc';
  validator: string;
  valName: 'pexmons';
  delegator: string;
  reward: number;
  height: number;
  rewardTime: string;
}

interface IApiRewardsData {
  total: number;
  rewardDetails: IApiRewardsDetails[];
}

interface IRewardsDetails {
  chainId: 'bsc';
  validator: string;
  valName: 'pexmons';
  delegator: string;
  reward: BigNumber;
  height: number;
  rewardTime: Date;
}

interface IRewardsData {
  total: number;
  rewardDetails: IRewardsDetails[];
}

export async function getStakingBalance(address: string) {
  const [{ data: stakingBalanceData }, { data: rewardsData }] =
    await Promise.all([
      await axios.get(
        `https://api.binance.org/v1/staking/accounts/${address}/balance`,
      ),
      await axios.get(
        `https://api.binance.org/v1/staking/chains/bsc/delegators/${address}/rewards?limit=100&offset=0`,
      ),
    ]);

  const rewards = mapRewards(rewardsData);

  const rewardsAmount = rewards.rewardDetails.reduce(
    (acc, item) => acc.plus(item.reward),
    new BigNumber(0),
  );

  return { ...mapStakingBalance(stakingBalanceData), rewardsAmount };
}

function mapRewards(data: IApiRewardsData): IRewardsData {
  return {
    ...data,
    rewardDetails: data.rewardDetails.map(item => ({
      ...item,
      reward: new BigNumber(item.reward),
      rewardTime: new Date(item.rewardTime),
    })),
  };
}

function mapStakingBalance(data: IApiStakingBalanceData): IStakingBalanceData {
  return {
    asset: data.asset,
    delegated: new BigNumber(data.delegated),
    unbonding: new BigNumber(data.unbonding),
  };
}
