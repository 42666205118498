import { useDispatchRequest, useQuery } from '@redux-requests/react';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { UserActions, UserActionTypes } from '../../store/actions/UserActions';
import { IUserInfo } from '../../store/apiMappers/userApi';
import { IStoreState } from '../../store/reducers';
import { isConnected } from '../../store/reducers/userReducer';
import { BRIDGE_PATH, BRIDGE_RECOVERY_PATH } from '../const';

export const useConnect = () => {
  const dispatch = useDispatchRequest();
  const location = useLocation();
  const isAuth = useSelector((state: IStoreState) => isConnected(state.user));
  const { data: userInfo, loading } = useQuery<IUserInfo | null>({
    type: UserActionTypes.FETCH_ACCOUNT_DATA,
  });

  const dispatchConnect = useCallback(() => {
    if (isAuth) {
      return;
    }
    const redirectOnConnect = getRedirectionPath(location.pathname);
    return dispatch(UserActions.connect(redirectOnConnect));
  }, [dispatch, isAuth, location.pathname]);

  const dispatchDisconnect = useCallback(() => {
    if (!isAuth) {
      return;
    }
    const redirectOnDisconnect =
      getRedirectionPath(location.pathname) || undefined;
    dispatch(UserActions.disconnect(redirectOnDisconnect));
  }, [dispatch, isAuth, location.pathname]);

  return {
    dispatchConnect,
    dispatchDisconnect,
    isAuth,
    blockChainType: userInfo?.blockchainType,
    loading,
  };
};

const getRedirectionPath = (currentPathname: string) => {
  switch (currentPathname) {
    case BRIDGE_PATH:
      return BRIDGE_PATH;

    case BRIDGE_RECOVERY_PATH:
      return BRIDGE_RECOVERY_PATH;

    default:
      return null;
  }
};
