import React from 'react';
import { Dialog, IconButton } from '@material-ui/core';
import { CancelIcon } from '../../../../UiKit/Icons/CancelIcon';
import { useSelectWalletModalStyles } from './SelectWalletModalStyles';
import { SelectWallet } from '../SelectWallet';

export interface IPolkadotExtensionModal {
  isOpened: boolean;
  onClose: () => void;
  isCloverWalletAvailable: boolean;
  isPolkadotWalletAvailable: boolean;
  handleConnect: () => void;
}

export const SelectWalletModal = ({
  isOpened,
  onClose,
  isCloverWalletAvailable,
  isPolkadotWalletAvailable,
  handleConnect,
}: IPolkadotExtensionModal) => {
  const classes = useSelectWalletModalStyles();

  return (
    <Dialog
      open={isOpened}
      onClose={onClose}
      scroll="body"
      fullWidth={true}
      PaperProps={{ square: false }}
      classes={{ paper: classes.root }}
    >
      <IconButton className={classes.close} onClick={onClose}>
        <CancelIcon size="xmd" />
      </IconButton>
      <SelectWallet
        isCloverWalletAvailable={isCloverWalletAvailable}
        isPolkadotWalletAvailable={isPolkadotWalletAvailable}
        handleConnect={handleConnect}
      />
    </Dialog>
  );
};
