import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useWalletBalanceItem = makeStyles<Theme>(theme => ({
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 14,
    lineHeight: '18px',
    color: theme.palette.text.secondary,
  },

  itemClickable: {
    cursor: 'pointer',
  },

  icon: {
    marginRight: theme.spacing(1),
    fontSize: 18,
    width: '1em',
    height: '1em',
  },
}));
