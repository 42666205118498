import { RequestAction } from '@redux-requests/core';
import { createAction } from 'redux-smart-actions';
import { BnbSdk } from '../api/BnbSdk';
import { ACTIONS_PREFIX } from '../const';
import { BinanceNetworkId } from '../types';
import { connect, IConnect } from './connect';

interface ISwitchNetworkReply {
  networkId: string;
}

export const switchNetworkToBbcMainnet = createAction<
  RequestAction<any, ISwitchNetworkReply>
>(`${ACTIONS_PREFIX}switchNetwork`, () => ({
  request: {
    promise: (async function (): Promise<ISwitchNetworkReply> {
      return new BnbSdk().switchNetwork(BinanceNetworkId.Mainnet);
    })(),
  },
  meta: {
    asMutation: true,
    showNotificationOnError: true,
    mutations: {
      [connect.toString()]: (
        data: IConnect,
        mutationData: ISwitchNetworkReply,
      ): IConnect => {
        return { ...data, chainId: mutationData.networkId };
      },
    },
  },
}));
