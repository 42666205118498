import loadable, { OptionsWithoutResolver } from '@loadable/component';
import React from 'react';
import { generatePath, useParams } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { QueryLoadingAbsolute } from '../../components/QueryLoading/QueryLoading';
import { createRouteConfig } from '../router/utils/createRouteConfig';
import { GuardRouteBinance } from './components/GuardRouteBinance';

const ROOT = '/staking/BNB';
const DASHBOARD_PATH = `${ROOT}/:id`;
const STAKE_PATH = `${ROOT}/stake/:id`;

export const RoutesConfig = createRouteConfig(
  {
    walletList: {
      path: ROOT,
      generatePath: () => generatePath(ROOT),
    },
    stake: {
      path: STAKE_PATH,
      generatePath: (id: string) => generatePath(STAKE_PATH, { id }),
      useParams: () => {
        const { id: walletAccountId } = useParams<{ id: string }>();
        return {
          walletAccountId,
        };
      },
    },
    dashboard: {
      path: DASHBOARD_PATH,
      generatePath: (id: string) => generatePath(DASHBOARD_PATH, { id }),
      useParams: () => {
        const { id: walletAccountId } = useParams<{ id: string }>();
        return {
          walletAccountId,
        };
      },
    },
  },
  ROOT,
);

const options: OptionsWithoutResolver<unknown> = {
  fallback: <QueryLoadingAbsolute />,
};

const WalletListBnb = loadable(
  async () =>
    import('./screens/WalletListBnb').then(module => module.WalletListBnb),
  options,
);

const StakerDashboardBnb = loadable(
  async () =>
    import('./screens/StakerDashboardBnb').then(
      module => module.StakerDashboardBnb,
    ),
  options,
);

const StakeBnb = loadable(
  async () => import('./screens/StakeBnb').then(module => module.StakeBnb),
  options,
);

export function getRoutes() {
  return (
    <Route path={RoutesConfig.root}>
      <Switch>
        <GuardRouteBinance exact path={ROOT} component={WalletListBnb} />

        <GuardRouteBinance
          exact
          path={DASHBOARD_PATH}
          component={StakerDashboardBnb}
        />

        <GuardRouteBinance exact path={STAKE_PATH} component={StakeBnb} />
      </Switch>
    </Route>
  );
}
