import loadable, { LoadableComponent } from '@loadable/component';
import React from 'react';
import { generatePath } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { QueryLoadingAbsolute } from '../../components/QueryLoading/QueryLoading';
import { withDefaultLayout } from '../layout';
import { createRouteConfig } from '../router/utils/createRouteConfig';

const ROOT = '/trading-cockpit';

export const RoutesConfig = createRouteConfig(
  {
    dashboard: {
      path: ROOT,
      generatePath: () => generatePath(ROOT),
    },
  },
  ROOT,
);

const DashboardContainer = withDefaultLayout(
  loadable(
    async () => import('./screens/Dashboard').then(module => module.Dashboard),
    {
      fallback: <QueryLoadingAbsolute />,
    },
  ) as LoadableComponent<any>,
);

export function getRoutes() {
  return (
    <Route path={RoutesConfig.root}>
      <Switch>
        <Route
          component={DashboardContainer}
          path={RoutesConfig.dashboard.path}
          exact
        />
      </Switch>
    </Route>
  );
}
