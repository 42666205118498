import { useDispatchRequest } from '@redux-requests/react';
import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { BINANCE_EXTENSION_LINK } from '../../../../common/const';
import { tHTML } from '../../../../common/utils/intl';
import { ConnectComponent } from '../../../../components/Connect';
import { UnsupportedNetwork } from '../../../../components/UnsupportedNetwork';
import { connect } from '../../actions/connect';
import { useConnectData } from '../../hooks/useConnectData';
import { useIsBinanceReady } from '../../hooks/useIsBinanceReady';
import { getIsSupportedChainId } from '../../utils/getIsSupportedChainId';
import { BnbLayout } from '../BnbLayout/BnbLayout';
import { NetworkSelector } from '../NetworkSelector';

export const GuardRouteBinance = (props: RouteProps) => {
  const dispatchRequest = useDispatchRequest();
  const isBinanceReady = useIsBinanceReady();
  const { isConnected, chainId } = useConnectData();

  const isSupportedNetwork = getIsSupportedChainId(chainId || '');

  const handleConnectClick = () => {
    dispatchRequest(connect());
  };

  if (isConnected && !isSupportedNetwork) {
    return (
      <BnbLayout>
        <UnsupportedNetwork networksSlot={<NetworkSelector />} />
      </BnbLayout>
    );
  }

  if (isConnected) {
    return (
      <BnbLayout>
        <Route {...props} />
      </BnbLayout>
    );
  }

  return (
    <BnbLayout>
      <ConnectComponent
        onConnectClick={handleConnectClick}
        btnDisabled={!isBinanceReady}
        info={
          isBinanceReady
            ? tHTML('connect.info-binance')
            : tHTML('connect.install-binance', {
                href: BINANCE_EXTENSION_LINK,
              })
        }
        networksSlot={<NetworkSelector />}
      />
    </BnbLayout>
  );
};
