export enum BinanceChainId {
  Testnet = 'Binance-Chain-Ganges',
  Mainnet = 'Binance-Chain-Tigris',
}

export enum BinanceNetworkId {
  Mainnet = 'bbc-mainnet',
  Testnet = 'bbc-testnet',
  SmartChainMainnet = 'bsc-mainnet',
  SmartChainTestnet = 'bsc-testnet',
}
